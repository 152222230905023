<template>
  <AdapterFrame>
    <h1 class="mx-8 mb-5 mt-10 text-3xl text-green-400">欢迎登录</h1>
    <van-form @submit="onSubmit" class="mx-4">
      <van-field
        v-model="username"
        name="loginName"
        label="账号"
        placeholder="用户名/邮箱/手机号"
        autocomplete="off"
        :rules="[{required: true, message: '请输入账号'}]"
      />
      <van-field
        v-model="password"
        class="customPassword"
        name="loginPwd"
        label="密码"
        placeholder="密码"
        autocomplete="off"
        :rules="[{required: true, message: '请输入密码'}]"
      />
      <div class="m-2 mt-6">
        <van-button class="bg-green-400 text-white rounded-md" block native-type="submit">登录</van-button>
      </div>
    </van-form>
  </AdapterFrame>
</template>

<script>
import AdapterFrame from '@/components/AdapterFrame.vue';
import {login} from '@/api';
import {store2sessionStorage} from '@/utils/syncStore.js';
import {pageTo} from '@/utils/router.js';
export default {
  name: 'Login',
  components: {
    AdapterFrame,
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    onSubmit(val) {
      login(val).then((res) => {
        this.$store.commit('setToken', res.token);
        store2sessionStorage();
        
        pageTo('home');
      });
    },
  },
};
</script>

<style scoped>
.customPassword ::v-deep input {
  -webkit-text-security: disc;
}
</style>
